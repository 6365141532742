import Icon from '@/assets/icons/applications.svg';
import type { RouterRecord } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';
import { RouteNames } from '@/types/router';

export const applicationsRoutes: RouterRecord = {
	path: '/applications',
	component: async () => import('@/layout/LayoutIndex.vue'),
	name: RouteNames.APPLICATIONS_ROOT,
	meta: {	title: 'applications', icon: Icon, group: 'modules', id: UserModuleIdentifiers.APPLICATIONS },
	children: [
		{
			path: '',
			component: async () => import('@/views/applications/ApplicationsIndex.vue'),
			name: RouteNames.APPLICATIONS_APPS_INDEX,
			meta: { title: 'apps_layout', icon: 'applications' },
			children: [
				{
					path: '',
					component: async () => import('@/views/applications/components/AppList.vue'),
					name: RouteNames.APPLICATIONS_APPS_LIST,
					meta: { title: 'mobile_applications', icon: 'applications' },
				},
				{
					path: ':app_id',
					component: async () => import('@/views/applications/components/AppForm.vue'),
					name: RouteNames.APPLICATIONS_APP_DETAIL,
					meta: { title: 'mobile_applications', icon: 'applications', edit: true },
				},
				{
					path: 'create',
					component: async () => import('@/views/applications/components/AppForm.vue'),
					name: RouteNames.APPLICATIONS_APP_CREATE,
					meta: { title: 'mobile_applications', icon: 'applications', edit: false },
				},
				{
					path: ':app_id/pages',
					component: async () => import('@/views/applications/ApplicationsPages.vue'),
					name: RouteNames.APPLICATIONS_APPS_PAGES_DEFAULT,
					meta: { title: 'mobile_applications', icon: 'applications', edit: false },
					children: [
						{
							path: ':locality_id',
							component: async () => import('@/views/applications/ApplicationsPages.vue'),
							name: RouteNames.APPLICATIONS_APPS_PAGES,
							meta: { title: 'mobile_applications', icon: 'applications', edit: false },
						},
					],
				},
			],
		},
		{
			path: 'editor/:app_id/page/:page_id/dashboard/:dashboard_id',
			component: async () => import('@/views/applications/components/AppUiEditor.vue'),
			name: RouteNames.APPLICATIONS_APP_UI,
			meta: { title: 'mobile_applications', icon: 'applications', edit: true },
		},
	],
};
